
.customoverlay {
    bottom: 65px;
    border: 1px solid #ccc;
    border-bottom: 2px solid #ddd;
    float: left;
    min-width: 57px;
    z-index: 10;
    padding: 9px 11px;
    background-color: #220348;
    border-radius: 20px;
}

.customoverlay:nth-of-type(n) {
    border: 0;
    box-shadow: 0px 1px 2px #888;
}



.customoverlay .title {
    display: block;
    text-align: center;
    color: #DEFD9A;
    font-size: 13px;
    font-weight: bold;

}

.customoverlay:after {
    content: '';
    position: absolute;
    margin-left: -3px;
    left: 45%;
    bottom: -12px;
    width: 13px;
    height: 12px;
    background: url('/src/assets/images/map/pin_botton.png');
    background-size: contain;
    background-repeat: no-repeat;
}
