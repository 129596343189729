.bottom-button-sheet {
  border-radius: 0;
  //border-top-left-radius: 0;
  //border-top-right-radius: 0;

  &.trans {
    -webkit-transition: border-radius 1s;
    transition: border-radius 1s;
  }

  &.open {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    transition: all 250ms ease-out;
  }
}

.order-info {
  overflow-x: hidden;

  &.open {
    max-height: 267px;
    overflow: hidden;
    transition: all 250ms ease-out;
  }

  &.close {
    max-height: 0;
    overflow: hidden;
    transition: all 250ms ease-out;
  }
}

.order-summary {
  overflow-x: hidden;

  &.open {
    max-height: 67px;
    overflow: hidden;
    transition: all 250ms ease-out;
  }

  &.close {
    max-height: 0;
    overflow: hidden;
    transition: all 250ms ease-out;
  }
}
