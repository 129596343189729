/*!
 * 입점샵 상세페이지 swiper scss
 */

#vendor-images-swiper {
  .swiper-pagination-fraction {
    left: auto;
    bottom: 0;
    right: 0;
    width: auto;

    .fraction-box {
      background-color: #140229;
      opacity: 0.85;
      border-radius: 12px;
      color: #fff;
      margin: 0 20px 8px 0;
      padding: 6px 12px;
      font-size: 11px;
      line-height: 12px;
    }
  }
}
