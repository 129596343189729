$primary-main: #212121;
$primary-light: rgb(77, 77, 77);
$primary-dark: rgb(23, 23, 23);
$primary-text: #fff;

$success-main: #259c24;
$success-light: #81c784;
$success-dark: #388e3c;
$success-text: #fff;

$text-secondary: #677788;

$font-family: 'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

// Design (by yuri)
$brand-color: #220648;
$brand-text: #defc99;

// 1KMWINE DESIGN
$product-img-border-radius: 14px;
$vendor-img-border-radius: 10px;

:export {
  brand_color: $brand-color;
  brand_text: $brand-text;

  font_family: $font-family;

  primary_main: $primary-main;
  primary_light: $primary-light;
  primary_dark: $primary-dark;
  primary_text: $primary-text;

  success_main: $success-main;
  success_light: $success-light;
  success_dark: $success-dark;
  success_text: $success-text;

  text_secondary: $text-secondary;

  product_img_border_radius: $product-img-border-radius;
}
