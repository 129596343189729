@import 'transitions';
@import 'palette';
@import 'kmwine-sweetalert2';

html img {
  /* iOS only */
  @supports (-webkit-touch-callout: none) {
    -webkit-touch-callout: none;
  }
}

span,
div,
p {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);

  &.is-bottom-nav-show {
    .WineOne-Footer {
      //padding-bottom: 80px !important;
    }
  }
}

/*
 * main banner - slick customize
 */
.main-slick-square {
  .slick-list {
    .slick-slide {
      > div {
        max-height: calc(100vw);
      }
    }
  }
}

.no-border-radius {
  border-radius: 0 !important;
}

.none-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent; /* make scrollbar transparent */
  }
}

.is-bottom-nav-show {
  #root {
    margin-bottom: 56px;
  }
}

.square-area {
  aspect-ratio: 1/1;
  // Fallback
  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
}

// 상품 이미지 박스
.product-image-box {
  overflow: hidden;
  border-radius: $product-img-border-radius;
  // 상품 이미지내 상품속성 라벨
  .product-label {
    border-bottom-left-radius: $product-img-border-radius;
    border-bottom-right-radius: $product-img-border-radius;
  }
}

// 상품 이미지
.product-image-wrapper {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  img:first-child {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;

    image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED */
    // image-rendering: optimizeQuality; /* Universal support since 2021 */
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    image-rendering: optimize-contrast; /* CSS3 Proposed */

    /* drag 막기 */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

// 와인샵 이미지
.vendor-image-box,
.vendor-image-wrapper {
  overflow: hidden;
  border-radius: $vendor-img-border-radius;
  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;

    image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED */
    // image-rendering: optimizeQuality; /* Universal support since 2021 */
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    image-rendering: optimize-contrast; /* CSS3 Proposed */

    /* drag 막기 */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

// font
.font-currency {
  font-family: 'D-Din', 'NanumSquare', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

// marked-text
.marked-text {
  position: relative;
  z-index: 1;
  .marked-text__label {
    padding: 0 4px;
    display: inline-block;
  }
  .marked-text__bg {
    background-color: $brand-text;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 13px;
    z-index: -1;
  }
}
